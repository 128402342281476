// imports
import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"
import useScript from "../hooks/useScript"
import Navbar from "../components/Navbar"
import BackgroundDiv from "../components/BackgroundDiv"


export default function ProjectPage({ data }) {
    useScript(withPrefix("scripts/jquery.min.js"));
    useScript(withPrefix("scripts/jquery.waypoints.min.js"));
    useScript(withPrefix("scripts/waypoint.js"));
    useScript("https://www.googletagmanager.com/gtag/js?id=UA-159994104-1");
    useScript(withPrefix("scripts/google-analytics.js"));

    return (
        <div>
        <Helmet></Helmet>
        <div id="colorlib-page">
            <Navbar />

            {/* main page contents */}
            <div id="colorlib-main">
                <BackgroundDiv className="hero-wrap" style={{height:"100vh", position:"fixed"}}>
                    <div className="overlay"></div>
                </BackgroundDiv>
                <div className="d-flex justify-content-center align-items-center" style={{paddingTop:"2rem", height: "100vh"}}>
                    <div className="col-md-8 text text-center">
                        <div className="desc">
                            <h1 className="mb-4" style={{fontSize:"1.5rem"}}>This page is under construction. Stay tuned!</h1>
                            <ul className="ftco-social mt-3">
                                <li className="ftco-animate"><a href="https://github.com/stevenaleung"><span className="icon-github" aria-label="github icon"></span></a></li>
                                <li className="ftco-animate"><a href="https://www.linkedin.com/in/stevenaleung"><span className="icon-linkedin" aria-label="linkedin icon"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* end of #colorlib-main */}
        </div>
        {/* end of #colorlib-page */}
        </div>
    )
}
